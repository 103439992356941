import { useCallback } from "react";
import {
  DataListItem,
  HeaderListItem,
} from "ReportsModule/Administration/SuperAdminsTable/types";
import { RowTypes } from "../rows";
import { SuperAdminInterface } from "types";
import { useSuperAdminInfoModal } from "TeamModule/components/MemberInfoModal/useSuperAdminInfoModal";

export const useSuperAdminListItemsBuilder = () => {
  const { openSuperAdminInfoModal } = useSuperAdminInfoModal();

  const onRowEditClick = useCallback(
    ({ accountId, event }: { accountId: number; event: React.MouseEvent }) => {
      event.stopPropagation();
      openSuperAdminInfoModal({ accountId });
    },
    [openSuperAdminInfoModal]
  );

  const listItemBuilder = useCallback(
    ({ superAdmins }: { superAdmins: SuperAdminInterface[] }) => {
      const headerListItem: HeaderListItem = {
        id: RowTypes.Header,
        rowType: RowTypes.Header,
        disableRowHover: false,
        isStickyHeader: true,
      };

      const formattedAccounts: DataListItem[] = superAdmins.map((member) => {
        return {
          id: String(member.id),
          rowType: RowTypes.Data,
          accountId: member.account_id,
          email: member.email,
          teamId: member.team_id,
          lastActive: member.last_active,
          name: member.name,
          member,
          onRowClick: (event) =>
            onRowEditClick({ accountId: member.account_id, event }),
        };
      });

      return [headerListItem, ...formattedAccounts];
    },
    [onRowEditClick]
  );

  return listItemBuilder;
};
