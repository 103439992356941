import { createAsyncThunk } from "@reduxjs/toolkit";
import {} from "@constants";
import {} from "utils";
import { AppStateType } from "reducers";
import {
  FetchAvailableDumpFilesParams,
  DeleteRealmsParams,
  FetchSandboxParams,
  CreateDumpFileParams,
  InitializeSandboxParams,
  FetchSandboxMembersCsv,
  UpdateSandboxParams,
  FetchSandboxCreatorsParams,
  FetchSandboxSuccessResponse,
  DeleteRealmPayload,
} from "SandboxModule/types";
import * as SandboxServices from "SandboxModule/services";
import { User } from "AuthModule/reducers";
import ApiClientManager from "core/apiClient";

export const deleteRealms = createAsyncThunk(
  "deleteRealms",
  async (
    params: DeleteRealmsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const auth_token = user?.auth_token;
    const { onSuccess, onFailure, realms } = params;

    try {
      if (!auth_token || !user) throw new Error("Not Authenticated");

      const meta: DeleteRealmPayload["meta"] = { name: user.account.name };

      const promises = realms.map((realm) =>
        SandboxServices.deleteRealm({
          params: { ...realm, meta },
          token: auth_token,
        })
      );

      const response = await Promise.allSettled(promises);

      onSuccess && onSuccess({ response });
      return response;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);

export const fetchSandboxes = createAsyncThunk<
  FetchSandboxSuccessResponse,
  FetchSandboxParams
>(
  "fetchSandboxes",
  async (
    {
      includeArchived,
      createdAtStartDate,
      createdAtEndDate,
      realmIdSearchKey,
      teamNameSearchKey,
      statuses,
      creators,
      tags,
      isoStateId,
      realmTypes,
      meta,
    }: FetchSandboxParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const auth_token = user?.auth_token;
    const { onSuccess, onFailure, realmId, tenantName } = meta || {};

    const config = {
      params: {
        includeArchived,
        createdAtStartDate,
        createdAtEndDate,
        realmIdSearchKey,
        teamNameSearchKey,
        statuses,
        creators,
        tags,
        realmTypes,
      },
      headers: ApiClientManager.buildUrlHeaders({ realmId, tenantName }),
    };

    try {
      const response = await SandboxServices.fetchSandboxes(
        {},
        config,
        auth_token as string
      );
      onSuccess && onSuccess({ response: response.data });
      return response.data;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);
export const fetchAvailableDumpFiles = createAsyncThunk(
  "fetchAvailableDumpFiles",
  async (
    params: FetchAvailableDumpFilesParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await SandboxServices.getAvailableDumpFiles(
        {},
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createDumpFile = createAsyncThunk(
  "createDumpFile",
  async (
    { onSuccess, fileName, onFailure }: CreateDumpFileParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const auth_token = user?.auth_token;

    try {
      const data = {
        fileName,
      };
      const response = await SandboxServices.createDumpFile(
        {},
        data,
        {},
        auth_token
      );

      onSuccess && onSuccess({ response });
      return response.data;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

export const initializeSandbox = createAsyncThunk(
  "initializeSandbox",
  async (
    { onFailure, onSuccess, realmId, tenant }: InitializeSandboxParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await SandboxServices.initializeSandbox(
        {},
        { realmId, tenant },
        {},
        auth_token
      );

      onSuccess && onSuccess({ response });
      return response.data;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

export const fetchSandboxMembersCsv = createAsyncThunk(
  "fetchSandboxMembersCsv",
  async (
    { onFailure, onSuccess, teamId }: FetchSandboxMembersCsv,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await SandboxServices.getSandboxMembersCsvUrl(
        {},
        { team_id: teamId },
        {},
        auth_token
      );

      onSuccess && onSuccess({ response });
      return response.data;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

export const updateSandbox = createAsyncThunk(
  "updateSandbox",
  async (
    { onFailure, onSuccess, realmId, teamName, creator, tags }: UpdateSandboxParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await SandboxServices.updateSandbox(
        {},
        { realmId, teamName, creator, tags },
        {},
        auth_token
      );

      onSuccess && onSuccess({ response });
      return response;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

// Probably need to put in another module though
// export const replaceMembers = createAsyncThunk(
//   "replaceMembers",
//   async (params, { dispatch, getState, rejectWithValue }) => {
//     try {
//       const {
//         authReducer: { user },
//       } = getState() as AppStateType;
//       const auth_token = user?.auth_token;
//       const response = {};
//       return response;
//     } catch (e) {
//       return rejectWithValue(e);
//     }
//   }
// );

export const fetchSandboxCreators = createAsyncThunk(
  "fetchSandboxCreators",
  async (
    { onFailure, onSuccess }: FetchSandboxCreatorsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await SandboxServices.getSandboxCreators(
        {},
        {},
        auth_token
      );
      onSuccess && onSuccess({ response });
      return response;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);
