import {
  FileCopy as FileCopyIcon,
  Check as CheckIcon,
} from "@material-ui/icons";
import { MouseEventHandler, useState } from "react";

type Props = {
  textToCopy: string;
};

const Copy: React.FC<Props> = ({ textToCopy }) => {
  const [isTextCopied, setIsTextCopied] = useState(false);

  const copyToClipboard: MouseEventHandler<SVGSVGElement> = (event) => {
    event.stopPropagation();

    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
      setIsTextCopied(true);

      // Reset state after 3 seconds
      setTimeout(() => {
        setIsTextCopied(false);
      }, 3000);
    }
  };

  return (
    <>
      {!isTextCopied && (
        <FileCopyIcon
          color="primary"
          style={{ cursor: !!textToCopy ? "pointer" : "not-allowed" }}
          className="copy-icon"
          onClick={copyToClipboard}
        />
      )}
      {isTextCopied ? <CheckIcon style={{ color: "green" }} /> : null}
    </>
  );
};

export default Copy;
