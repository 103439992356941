import {
  Table,
  TableRow,
  TableContainer,
  TableBody,
  TableHead,
  Collapse,
  Box,
  Toolbar,
  Paper,
} from "@material-ui/core";
import styled from "styled-components";
import { CellWithBorder, CellWithBorderClassNames } from "shared/styles";
import findIndex from "lodash/findIndex";
import { conditionallyRenderElement } from "utils";
import cn from "classnames";
import { ReactNode } from "react";
import { MergeExclusive, ValueOf } from "type-fest";
import { ORDER_DIRECTION } from "@constants";

export const StyledTableRow = styled(TableRow)`
  &.clickable {
    cursor: pointer;
  }

  &.has-hover-effect {
    &:hover {
      td {
        background-color: #f7f9fc !important;
      }
    }
  }
`;

type ColumnId = string;

/**
 *
 * TODO: Column should really be an for flexibility,
 * so need to go to other tables that use `useMuiTableBuilderV2` to make sure it uses the correct type
 *
 * Also update `HeaderCell` in Cells folder
 */
export type Column = MergeExclusive<
  ColumnId,
  {
    id: ColumnId;
    label?: string;
    tooltip?: ReactNode;
    sortDisabled?: boolean;
  }
>;

type RowType = string;

type DefaultCustomRowProps = Record<string, any>;

export interface TableSortProps {
  orderDirection: ValueOf<typeof ORDER_DIRECTION>;
  orderBy: string;
  /**
   * optional, because some table does not support sorting at all
   */
  onSort?: (columnId: ColumnId) => void;
}

export type ListItem<Data = {}> = {
  /**
   * Unique key of thist ListItem
   */
  id: string;

  /**
   * Type of the row, must be included in each item in mainList
   */
  rowType: RowType;

  /**
   * Items that are grouped under this current list items
   * i.e: { listItems: [{id: child2}] }
   */
  listItems?: ListItem[];

  /**
   *
   * A record of string (which has to represent a valid column) and classname as string for that cell
   * Useful for adding border. Take a look at the classes that CellWithBorder support
   *
   * i.e: { ['header'1]: 'class1', ['header'2]: 'class2' }
   */
  cellClassNameByColumns?: Record<ColumnId, CellWithBorderClassNames[]>;

  /**
   * This prop must be provided if you want a row to be able to collapse and expand
   */
  collapseProps?: {
    shouldWrapWithCollapse: boolean;
    isCollapse: boolean;
  };

  /**
   * Provide to have the hover effect on row
   * Default: false
   */

  disableRowHover?: boolean;

  /**
   * Row click action
   */
  onRowClick?: (event: React.MouseEvent) => void;

  /**
   * If hasStickyHeader is provided, the list item to acts as a sticky header will need this flag.
   * Note that only ONE (1) row can be a sticky header
   */
  isStickyHeader?: boolean;
} & Data;

export type CellProps<Data = any, CustomRowProps = DefaultCustomRowProps> = {
  customRowProps: CustomRowProps;
  column: Column;
} & ListItem<Data>; // Should type inside the cell component

type MainList = {
  listItems: ListItem[];
};

export type TableConfigV2 = {
  /**
   * Header title of the table
   */
  tableHeaderTitle: string;

  /* ------------------------------ Sticky Header ----------------------------- */

  /**
   * If provided, one of the list item must have isStickyHeader = true
   */
  hasStickyHeader?: boolean;

  /* -------------------------------------------------------------------------- */

  /**
   *
   */
  tableMaxHeight?: string;

  /**
   * Determine how many columns will this table have
   */
  columns: Column[];

  /**
   * Setting the min width of each column
   */
  columnsMinWidth?: Record<ColumnId, number>;

  /**
   * An object of row types, this is to make sure devs dont forget to include rowType in each item of mainList
   */
  rowTypes: Record<RowType, RowType>;

  /**
   * The key must represent a row type that is present on rowTypes
   */
  cellsByRowTypesAndColumns: Record<
    RowType,
    Record<ColumnId, (props: CellProps) => Nullable<JSX.Element>>
  >;

  hideToolbar?: boolean;
};

export type UseMuiTableBuilderV2Props = TableConfigV2 & {
  isLoading?: boolean;

  /* ------------------------------- Main Header ------------------------------ */
  renderHeaderTools?: () => JSX.Element;

  /* ---------------------------------- Body ---------------------------------- */
  /**
   *
   */
  mainList: MainList;

  /**
   * Anything in this object will be presented on all rows
   */
  customRowProps?: DefaultCustomRowProps;

  /* --------------------------------- Footer --------------------------------- */

  /**
   * Function to render the footer component's body
   */
  renderFooterOptions?: () => JSX.Element;
};

const defaultColumnMinWidth = 200;

const renderRow = ({
  listItem,
  rowTypes,
  columns,
  columnsMinWidth,
  cellsByRowTypesAndColumns,
  customRowProps,
}: {
  listItem: ListItem;
  rowTypes: UseMuiTableBuilderV2Props["rowTypes"];
  columns: UseMuiTableBuilderV2Props["columns"];
  columnsMinWidth: UseMuiTableBuilderV2Props["columnsMinWidth"];
  cellsByRowTypesAndColumns: UseMuiTableBuilderV2Props["cellsByRowTypesAndColumns"];
  customRowProps: UseMuiTableBuilderV2Props["customRowProps"];
}) => {
  const {
    rowType,
    cellClassNameByColumns,
    collapseProps,
    disableRowHover = false,
    id,
    onRowClick,
  } = listItem;

  if (!rowTypes[rowType]) return null;

  const { shouldWrapWithCollapse, isCollapse } = collapseProps || {};

  const rowKey = `${id}-${rowType}`;

  return (
    <StyledTableRow
      onClick={onRowClick}
      key={rowKey}
      className={cn({
        [rowType]: rowType,
        clickable: !!onRowClick,
        "has-hover-effect": !disableRowHover,
      })}
    >
      {columns.map((column) => {
        const columnId = typeof column === "object" ? column.id : column;

        const Cell = cellsByRowTypesAndColumns[rowType][columnId];
        const columnMinWidth =
          columnsMinWidth?.[columnId] ?? defaultColumnMinWidth;
        const cellClassName = (cellClassNameByColumns?.[columnId] || []).join(
          " "
        );

        const CellContent = shouldWrapWithCollapse ? (
          <Collapse in={isCollapse} timeout="auto" unmountOnExit>
            <Cell
              customRowProps={customRowProps}
              {...listItem}
              column={column}
            />
          </Collapse>
        ) : (
          <Cell customRowProps={customRowProps} {...listItem} column={column} />
        );

        const cellKey = `${rowKey}-${columnId}`;

        return (
          <CellWithBorder
            key={cellKey}
            style={{ backgroundColor: "#fff", minWidth: `${columnMinWidth}px` }} // This minWidth makes the table scroll x
            align="center"
            className={cellClassName}
          >
            {CellContent}
          </CellWithBorder>
        );
      })}
    </StyledTableRow>
  );
};

const getTableList = ({
  hasStickyHeader,
  mainList,
}: {
  hasStickyHeader: UseMuiTableBuilderV2Props["hasStickyHeader"];
  mainList: UseMuiTableBuilderV2Props["mainList"];
}) => {
  const { listItems } = mainList;

  const flatList = flattenList(listItems);

  if (hasStickyHeader) {
    const indexOfStickyHeader = findIndex(flatList, "isStickyHeader");

    const tableHeadList: ListItem[] = flatList.slice(
      0,
      indexOfStickyHeader + 1
    );
    const tableBodyList: ListItem[] = flatList.slice(indexOfStickyHeader + 1);

    return {
      tableHeadList,
      tableBodyList,
    };
  }
  return {
    tableHeadList: [],
    tableBodyList: flatList,
  };
};

const useMuiTableBuilderV2 = ({
  tableMaxHeight = "500px",
  hasStickyHeader = false,
  columns,
  columnsMinWidth,
  cellsByRowTypesAndColumns,
  mainList,
  rowTypes,
  tableHeaderTitle,
  isLoading = false,
  customRowProps = {},
  renderFooterOptions,
  renderHeaderTools,
  hideToolbar,
}: UseMuiTableBuilderV2Props) => {
  const { tableHeadList, tableBodyList } = getTableList({
    hasStickyHeader,
    mainList,
  });

  const TableComponent = (
    <Box m="1rem 0">
      <Paper elevation={0}>
        {!hideToolbar ? (
          <Toolbar>
            {tableHeaderTitle ? (
              <Box component={"h6"} width={"100%"} fontSize="1rem">
                {tableHeaderTitle}
              </Box>
            ) : (
              <></>
            )}
            {renderHeaderTools ? renderHeaderTools() : <></>}
          </Toolbar>
        ) : (
          <></>
        )}
        {conditionallyRenderElement({
          isLoading,
          error: undefined,
          content: (
            <>
              <TableContainer
                style={{
                  height: "auto",
                  maxHeight: tableMaxHeight,
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    {tableHeadList.map((listItem) =>
                      renderRow({
                        listItem,
                        rowTypes,
                        cellsByRowTypesAndColumns,
                        customRowProps,
                        columns,
                        columnsMinWidth,
                      })
                    )}
                  </TableHead>
                  <TableBody>
                    {tableBodyList.map((listItem) =>
                      renderRow({
                        listItem,
                        rowTypes,
                        cellsByRowTypesAndColumns,
                        customRowProps,
                        columns,
                        columnsMinWidth,
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Footer */}
              {renderFooterOptions ? (
                <Box
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"flex-end"}
                  height="3rem"
                  padding={"10px"}
                >
                  {renderFooterOptions()}
                </Box>
              ) : (
                <></>
              )}
            </>
          ),
        })}
      </Paper>
    </Box>
  );

  return {
    TableComponent,
  };
};

export default useMuiTableBuilderV2;

const flattenList = (listItems: ListItem[]) => {
  const flatList: ListItem[] = [];
  for (const item of listItems) {
    const { listItems } = item || {};

    flatList.push(item);

    if (listItems) {
      // Has list items (children), build them
      flatList.push(...flattenList(listItems));
    }
  }

  return flatList;
};
