import { useCallback, useMemo } from "react";
import { getTableConfig } from "./tableConfigs";
import useMuiTableBuilderV2, {
  StyledTableRow,
} from "hooks/useMuiTableBuilderV2";
import { Box, TablePagination, TablePaginationProps } from "@material-ui/core";
import { useSuperAdminListItemsBuilder } from "./helpers/useSuperAdminListItemsBuilder";
import { SuperAdminInterface } from "types";
import { useSelector } from "react-redux";
import { selectSuperAdminTableFilter } from "selectors";
import useRequestSort from "hooks/useRequestSort";
import { CustomRowProps } from "ReportsModule/Administration/SuperAdminsTable/types";
import { StyledEditIcon } from "TeamModule/components/TeamMembersTable/DataCells/TeamMemberNameCell";
import styled from "styled-components";

const tableConfig = getTableConfig();

export const SuperAdminsTable = ({
  superAdmins,
  paginationProps,
}: {
  superAdmins: SuperAdminInterface[];
  paginationProps: Pick<
    TablePaginationProps,
    "count" | "page" | "onPageChange" | "rowsPerPage" | "rowsPerPageOptions"
  >;
}) => {
  const listItemsBuilder = useSuperAdminListItemsBuilder();
  const filter = useSelector(selectSuperAdminTableFilter);
  const { toggleSort } = useRequestSort({ filter });

  const mainList = useMemo(() => {
    return {
      listItems: listItemsBuilder({ superAdmins }),
    };
  }, [listItemsBuilder, superAdmins]);

  const onSort = useCallback<CustomRowProps["onSort"]>(
    (columnId) => {
      toggleSort({ orderBy: columnId });
    },
    [toggleSort]
  );

  const customRowProps: CustomRowProps = useMemo(
    () => ({
      orderDirection: filter.orderDirection,
      orderBy: filter.orderBy,
      onSort,
    }),
    [filter.orderDirection, filter.orderBy, onSort]
  );

  const renderFooterOptions = useCallback(() => {
    return <TablePagination color="primary" {...paginationProps} />;
  }, [paginationProps]);

  const { TableComponent } = useMuiTableBuilderV2({
    ...tableConfig,
    mainList,
    customRowProps,
    tableMaxHeight: "1000px",
    renderFooterOptions,
  });

  return <StyledContainer>{TableComponent}</StyledContainer>;
};

const StyledContainer = styled(Box)`
  ${StyledEditIcon} {
    visibility: hidden;
  }

  ${StyledTableRow} {
    &:hover {
      ${StyledEditIcon} {
        visibility: visible;
      }
    }
  }
`;
