import { isDemo } from "core/apiClient";
import { startCase } from "lodash";
import { Tenant } from "TenantModule/types";
import { GenericTeamInterface, Realm, TeamMembershipType } from "types";

/**
 * A utility function that takes a valid numeric string, commas seperated
 * and convert into an array of number
 * @params {numericString} - string
 * @returns {number[]}
 */
export const parseNumericStringToArray = (numericString: string) => {
  return numericString.split(",").map((id: string) => parseInt(id.trim()));
};

/**
 *  A utility function that removes all underscore and only capitalizes first letter of each word
 * @params {string}
 * @returns {string}
 */

export const removeUnderscoresAndCapitalizeFirstLetterOfEachWord = (
  str: string
) => {
  return startCase(str.replace("_", " ").toLowerCase());
};

const idSerializationSeperator = "_____";

export const serializeId = (arr: (string | number)[]) => {
  return arr.join(idSerializationSeperator);
};

export const deserializeId = (str: string) =>
  str.split(idSerializationSeperator);

export const getCopyText = ({
  team,
  realm,
  tenant,
  email,
  accountId,
}: {
  team?: GenericTeamInterface;
  realm?: Realm;
  tenant?: Tenant;
  accountId?: TeamMembershipType["account_id"];
  email?: TeamMembershipType["email"];
}) => {
  let string = `${process.env.REACT_APP_UI_ENV} env`;

  if (tenant && !isDemo) {
    string += `, Tenant ${tenant.tenantName}`;
  }

  if (realm) {
    string += `, ${realm.team_name} (${realm.realm_id})`;
  }

  if (team) {
    string += `, ${team.name} (${team.id})`;
  }

  if (email) {
    string += `, ${email}`;
  }

  if (accountId) {
    string += ` (${accountId})`;
  }

  return string;
};
