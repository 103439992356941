import { useArrayTagsInput } from "hooks/useArrayItems/useArrayTagsInput";
import { useRef, useState } from "react";
import { useTenantRealmDropdownsFlow } from "TenantModule/hooks/useTenantRealmDropdownsFlow";

export const useSandboxModalFields = () => {
  const [teamNameInputError, setTeamNameInputError] = useState("");
  const teamNameRef = useRef(document.createElement("input"));
  const creatorRef = useRef(document.createElement("input"));
  const {
    selectedTenant,
    selectedGlobalTenant,
  } = useTenantRealmDropdownsFlow();

  const {
    addTag,
    removeTag,
    inputRef,
    tagsList,
    tagError,
    setTagsList,
    clearErrorAndTags,
    handleCheckboxChange,
    standardTags,
    clearStandardTags,
    setStandardTags,
  } = useArrayTagsInput();

  return {
    addTag,
    removeTag,
    inputRef,
    tagsList,
    tagError,
    setTagsList,
    clearErrorAndTags,
    handleTagCheckboxChange: handleCheckboxChange,
    standardTags,
    clearStandardTags,
    teamNameInputError,
    setTeamNameInputError,
    teamNameRef,
    creatorRef,
    setStandardTags,
    selectedTenant,
    selectedGlobalTenant,
  };
};
